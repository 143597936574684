import Swiper from 'swiper';
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper/modules';

(function() {
  const SELECTORS = {
    selector: '[pd-selector]',
    selectorHeader: '[pd-selector-header]',
    selectedValue: '[pd-selector-selected-value]',
    subSelector: '[pd-subselector]',
    option: '[pd-option]',
    inputOption: '[pd-option] input[type="radio"]',
    pdTabs: '[pd-tabs]',
    pdTab: '[pd-tab]',
    pdTabContent: '[pd-tab-content]',
    pdAccordion: '.pd-accordion',
    pdAccordionHeader: '.pd-accordion-header',
    pdServiceAccordion: '.pd-service-accordion',
    pdServiceAccordionHeader: '.pd-service-accordion-header',
    pdWaveAnimation: '.pd-wave-animation',
    pdOurTeam: '.pd-our-team',
    pdOurTeamReadmore: '.pd-our-team-read-more',
    pdTeamMemberDrawer: '.pd-our-team-member-drawer',
    pdOurTeamDrawerPrevious: '.pd-our-team-drawer-previous',
    pdOurTeamDrawerNext: '.pd-our-team-drawer-next',
    pdFaqDropdown: '.pd-faq-dropdown',
    pdFaqDropdownBtn: '.pd-faq-dropdown-btn',
    pdFaqChosenValue: '.pd-faq-dropdown-chosen-value',
    pdFaqContent: '.pd-faq-dropdown-content',
    pdFaqItem: '.pd-faq-dropdown-item',
    pdFaqDesktopMenuItems: '.pd-faq-menu-desktop-menu-items',
    pdFaqDesktopItem: '.pd-faq-desktop-item-url',
  };

  const CLASSES = {
    isExpanded: 'is-expanded',
    isHidden: 'is-hidden',
    isClosed: 'is-closed',
    isActive: 'is-active',
    isZoomIn: 'is-zoom-in',
    isReady: 'is-ready',
    isClicked: 'is-clicked',
  };

  function pdTabs() {
    const ELM_pdTabs = document.querySelectorAll(SELECTORS.pdTab);
    ELM_pdTabs?.forEach(ELM_pdTab => ELM_pdTab.addEventListener('click', () => {
      const ELM_wrapper = ELM_pdTab.closest(SELECTORS.pdTabs);
      ELM_wrapper.querySelectorAll(SELECTORS.pdTab)?.forEach(tab => tab.classList.remove(CLASSES.isActive));
      ELM_wrapper.querySelectorAll(SELECTORS.pdTabContent)?.forEach(tabContent => tabContent.classList.remove(CLASSES.isActive));
      ELM_pdTab.classList.add(CLASSES.isActive);
      ELM_wrapper.querySelector(`[pd-tab-content="${ELM_pdTab.getAttribute('pd-tab')}"]`)?.classList.add(CLASSES.isActive);
    }));
  }

  pdTabs();

  function carouselOurTeam() {
    const ELM_pdOurTeam = document.querySelector(SELECTORS.pdOurTeam);
    if (ELM_pdOurTeam) {
      let swiper = new Swiper('.pd-swiper-our-team', {
        modules: [FreeMode, Pagination, Navigation],
        spaceBetween: 32,
        slidesPerView: 1,
        breakpoints: {
          760: {
            slidesPerView: 2,
          },
          1100: {
            slidesPerView: 3,
          },
          1400: {
            slidesPerView: 3.5,
          },
        },
        effect: 'fade',
        freeMode: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });

    }
  }

  carouselOurTeam();

  function pdDrawers() {
    document.querySelectorAll('.pd-drawer-close-button')?.forEach(btn => btn.addEventListener('click', () => {
      btn.closest('.pd-drawer')?.classList.remove('is-open');
      document.querySelector('html').classList.remove('overflow-hidden');
    }));

    document.querySelectorAll('.pd-drawer')?.forEach(ELM_drawer => {
      ELM_drawer.addEventListener('click', e => {
        const ELM_drawerContainer = ELM_drawer.querySelector('.pd-drawer-container');
        if (!ELM_drawerContainer.contains(e.target)) {
          ELM_drawer.classList.remove('is-open');
          document.querySelector('html').classList.remove('overflow-hidden');
        }
      });
    });
  }

  pdDrawers();

  function editSelectionDrawer() {
    document.querySelectorAll('[pd-open-edit-drawer-button]')?.forEach(btn => btn.addEventListener('click', () => {
      document.querySelector('.pd-edit-selection-drawer')?.classList.add('is-open');
    }));
  }

  editSelectionDrawer();

  function configureDrawer() {
    document.querySelectorAll('[pd-open-configure-drawer]')?.forEach(btn => btn.addEventListener('click', () => {
      document.querySelector('.pd-configure-drawer')?.classList.add('is-open');
    }));
  }

  configureDrawer();

  function pdCartDrawer() {
    document.querySelectorAll('[pd-open-cart-drawer]')?.forEach(btn => btn.addEventListener('click', () => {
      document.querySelector('.pd-cart-drawer')?.classList.add('is-open');
    }));
  }

  pdCartDrawer();


  function pdTeamMemberDrawerUpdate(id, items) {
    for (let i = 0; i < 4; i++) {
      const it = items[i];
      if (!it?.classList.contains('is-open')) {
        it?.classList.add('is-open');
      }
      if (i !== id) {
        if (!it?.classList.contains('opacity-0')) {
          it?.classList.add('opacity-0');
        }
      } else {
        if (it?.classList.contains('opacity-0')) {
          it?.classList.remove('opacity-0');
        }
      }
    }
  }

  function pdTeamMemberDrawer() {
    let id = 0;
    const items = document.querySelectorAll('.pd-our-team-member-drawer');

    for (let i = 0; i < 4; i++) {
      document.querySelectorAll(`${SELECTORS.pdOurTeamReadmore}-${i}`)?.forEach(btn => btn.addEventListener('click', () => {
        id = i;
        pdTeamMemberDrawerUpdate(id, items);
      }));
    }
    document.querySelectorAll(SELECTORS.pdOurTeamDrawerPrevious)?.forEach(btn => btn.addEventListener('click', () => {
      if (id > 0) {
        id = id - 1;
      } else {
        id = 3;
      }
      pdTeamMemberDrawerUpdate(id, items);
    }));
    document.querySelectorAll(SELECTORS.pdOurTeamDrawerNext)?.forEach(btn => btn.addEventListener('click', () => {
      if (id < 3) {
        id = id + 1;
      } else {
        id = 0;
      }
      pdTeamMemberDrawerUpdate(id, items);
    }));
  }

  pdTeamMemberDrawer();

  function pdAccordions() {
    document.querySelectorAll(SELECTORS.pdAccordionHeader)?.forEach(ELM_header => ELM_header.addEventListener('click', () => {
      ELM_header.closest(SELECTORS.pdAccordion)?.classList.toggle(CLASSES.isExpanded);
    }));
  }

  pdAccordions();

  function pdServiceAccordions() {
    document.querySelectorAll(SELECTORS.pdServiceAccordionHeader)?.forEach(ELM_header => ELM_header.addEventListener('click', () => {
      ELM_header.closest(SELECTORS.pdServiceAccordion)?.classList.toggle(CLASSES.isExpanded);
    }));
  }

  pdServiceAccordions();

  function toggleFaqDropdown() {
    const dropDownIcon = document.querySelectorAll('.pd-faq-dropdown-icon');
    const inputField = document.querySelector(SELECTORS.pdFaqChosenValue);
    const dropdown = document.querySelector(SELECTORS.pdFaqContent);
    const dropdownArray = [...document.querySelectorAll(SELECTORS.pdFaqItem)];
    let valueArray = [];
    dropdownArray.forEach(item => {
      valueArray.push(item.textContent);
    });

    if (inputField) {
      inputField.addEventListener('input', () => {
        dropdown.classList.add('is-opened');
        let inputValue = inputField.value.toLowerCase();
        if (inputValue.length > 0) {
          for (let j = 0; j < valueArray.length; j++) {
            if (!(inputValue.substring(0, inputValue.length) === valueArray[j].substring(0, inputValue.length).toLowerCase())) {
              dropdownArray[j].classList.add('is-closed');
            } else {
              dropdownArray[j].classList.remove('is-closed');
            }
          }
        } else {
          for (let i = 0; i < dropdownArray.length; i++) {
            dropdownArray[i].classList.remove('is-closed');
          }
        }
      });
      inputField.addEventListener('focus', () => {
        inputField.placeholder = '';
        dropdown.classList.add('is-opened');
        dropDownIcon.forEach(e => e.classList.add('is-opened'));
        dropdownArray.forEach(dropdown => {
          dropdown.classList.remove('is-closed');
        });
      });

      inputField.addEventListener('blur', () => {
        inputField.placeholder = '';
        dropdown.classList.remove('is-opened');
        dropDownIcon.forEach(e => e.classList.remove('is-opened'));
      });
    }

    dropdownArray.forEach(item => {
      item.addEventListener('click', (e) => {
        inputField.value = item.textContent;
        let sectionId = 'subject_1';
        for (let i = 1; i <= 14; i++) {
          if (inputField.value === 'Subject ' + i) {
            sectionId = 'subject_' + i;
            break;
          }
        }
        const section = document.getElementById(sectionId);
        if (section) {
          const sectionPosition = section.getBoundingClientRect().top + window.scrollY - 100;
          // Scroll to the adjusted position
          window.scrollTo({ top: sectionPosition, behavior: 'smooth' });
        }
        dropdownArray.forEach(dropdown => {
          if (dropdown.classList.contains('is-opened')) {
            dropdown.classList.add('is-closed');
          } else {
            dropdown.classList.add('is-opened');
          }
        });
      });
    });

    document.addEventListener('click', (e) => {
      if (dropdown && inputField) {
        const isDropdown = dropdown.contains(e.target);
        const isInput = inputField.contains(e.target);
        if (!isDropdown && !isInput) {
          dropdown.classList.remove('is-opened');
        }
      }
    });
  }

  toggleFaqDropdown();

  const desktopMenu = document.querySelector(SELECTORS.pdFaqDesktopMenuItems);
  const desktopChevronLeft = document.querySelector('.pd-faq-menu-desktop-icon-left');
  const desktopChevronRight = document.querySelector('.pd-faq-menu-desktop-icon-right');

  if(desktopMenu) {
    const desktopMaxToScroll = (desktopMenu.scrollWidth - desktopMenu.clientWidth);

    function hideAndDisplayChevronDesktopMenu() {
      if (desktopMenu.scrollLeft > 0) {
        if (desktopChevronLeft.classList.contains(CLASSES.isHidden)) {
          desktopChevronLeft.classList.remove(CLASSES.isHidden);
        }
      } else {
        desktopChevronLeft.classList.add(CLASSES.isHidden);
      }

      if (desktopMenu.scrollLeft < desktopMaxToScroll -10) {
        if (desktopChevronRight.classList.contains(CLASSES.isHidden)) {
          desktopChevronRight.classList.remove(CLASSES.isHidden);
        }
      } else {
        desktopChevronRight.classList.add(CLASSES.isHidden);
      }
    }

    function faqMenuDesktopScroll() {
      // click to scroll to subject information
      const items = document.querySelectorAll(SELECTORS.pdFaqDesktopItem);
      items?.forEach(e => e.addEventListener('click', () => {
        const section = document.getElementById(e.ariaValueText);
        if (section) {
          const sectionPosition = section.getBoundingClientRect().top + window.scrollY - 100;

          // Scroll to the adjusted position
          window.scrollTo({ top: sectionPosition, behavior: 'smooth' });
        }
      }));

      // add event scroll by mouse and wheel
      if (desktopMenu) {
        desktopMenu.addEventListener('wheel', (event) => {
          event.preventDefault();
          desktopMenu.scrollBy({
            left: event.deltaY < 0 ? -30 : 30,
          });

          hideAndDisplayChevronDesktopMenu()
        });

        let isMouseDown = false;
        let startX, scrollLeft;

        desktopMenu.addEventListener('mousedown', (e) => {
          isMouseDown = true;
          startX = e.pageX - desktopMenu.offsetLeft;
          scrollLeft = desktopMenu.scrollLeft;
        });

        desktopMenu.addEventListener('mouseup', () => {
          isMouseDown = false;
        });

        desktopMenu.addEventListener('mouseleave', () => {
          isMouseDown = false;
        });

        desktopMenu.addEventListener('mousemove', (e) => {
          e.preventDefault();
          if (!isMouseDown) return;
          const x = e.pageX - desktopMenu.offsetLeft;
          const scroll = x - startX;
          desktopMenu.scrollLeft = scrollLeft - scroll;
        });

        desktopChevronLeft.classList.add(CLASSES.isHidden);
        desktopChevronLeft.addEventListener('click', () => {
          desktopChevronLeft.classList.add('pd-faq-menu-desktop-icon-left-clicked');
          setTimeout(() => {
            desktopChevronLeft.classList.remove('pd-faq-menu-desktop-icon-left-clicked');
          }, 400)

          if (desktopMenu.scrollLeft > 0) {
            desktopMenu.scrollLeft -= 100;
            if (desktopMenu.scrollLeft === 0) {
              desktopChevronLeft.classList.add(CLASSES.isHidden);
            }
            if (desktopChevronRight.classList.contains(CLASSES.isHidden)) {
              desktopChevronRight.classList.remove(CLASSES.isHidden);
            }
          } else {
            desktopChevronLeft.classList.add(CLASSES.isHidden);
          }
        });
        desktopChevronRight.addEventListener('click', () => {
          desktopChevronRight.classList.add('pd-faq-menu-desktop-icon-right-clicked');
          setTimeout(() => {
            desktopChevronRight.classList.remove('pd-faq-menu-desktop-icon-right-clicked');
          }, 400)

          if (desktopMenu.scrollLeft < desktopMaxToScroll) {
            desktopMenu.scrollLeft += 100;
            if (desktopMenu.scrollLeft >= desktopMaxToScroll) {
              desktopChevronRight.classList.add(CLASSES.isHidden);
            }
            if (desktopChevronLeft.classList.contains(CLASSES.isHidden)) {
              desktopChevronLeft.classList.remove(CLASSES.isHidden);
            }
          } else {
            desktopChevronRight.classList.add(CLASSES.isHidden);
          }
          if (desktopMenu.scrollLeft <= 0) {
            desktopChevronLeft.classList.add(CLASSES.isHidden);
          }
        });
      }
    }

    faqMenuDesktopScroll();

    function scrollAccordionDesktopMenu(element) {
      if(element.offsetLeft > desktopMenu.scrollLeft + 300) {
        desktopMenu.scrollBy({
          left: (element.offsetLeft - desktopMenu.scrollLeft) / 4,
        });
      }
      if(element.offsetLeft < desktopMenu.scrollLeft + 100) {
        desktopMenu.scrollBy({
          left: (element.offsetLeft - desktopMenu.scrollLeft) - 200,
        });
      }
      if(element.offsetLeft < desktopMenu.scrollLeft) {
        desktopMenu.scrollBy({
          left: (element.offsetLeft - desktopMenu.scrollLeft) * 4,
        });
      }
      hideAndDisplayChevronDesktopMenu()
    }

    function handleScrollSubjectMenu() {
      const items = document.querySelectorAll(SELECTORS.pdFaqDesktopItem);
      const subjects = document.querySelectorAll('.pd-faq-subject');
      const underline = document.getElementById('pd-faq-desktop-item-underline');

      // Iterate through the div elements
      for (const subject of subjects) {
        // Check if the top of the element is within the viewport
        const rect = subject.getBoundingClientRect();
        if (rect.top >= 100 && rect.top <= window.innerHeight) {
          // If the top of the element is within the viewport, apply styling or get its ID
          const firstVisibleId = subject.id;
          const menuItem = document.querySelector(`#desktop_menu_${firstVisibleId}`)
          scrollAccordionDesktopMenu(menuItem)
          items.forEach(e => {
            const ariaValueText = e.getAttribute('aria-valuetext');
            if (ariaValueText === firstVisibleId) {
              underline.style.width = `${e.getBoundingClientRect().width}px`;
              underline.style.left = `${e.offsetLeft}px`;
            }
            setTimeout(() => {
              if (ariaValueText === firstVisibleId) {
                if (!e.classList.contains(CLASSES.isClicked)) {
                  e.classList.add(CLASSES.isClicked);
                }
              } else {
                if (e.classList.contains(CLASSES.isClicked)) {
                  e.classList.remove(CLASSES.isClicked);
                }
              }
            }, 10);
          });
          break; // Exit the loop once the first visible element is found
        }
      }
    }

    if (window.location.pathname.includes('/faq.html') && window.innerWidth > 1024) {
      window.addEventListener('scroll', handleScrollSubjectMenu);
    } else {
      window.removeEventListener('scroll', handleScrollSubjectMenu);
    }
  }

  (function() {
    const banner_btn = document.querySelector('.pd-hero-banner-button');

    if (banner_btn) {
      if (!location.pathname.includes('service')) banner_btn.remove();
      else {
        banner_btn.addEventListener('click', () => {
          document.querySelector('html').classList.add('overflow-hidden');
          document.querySelector('.service-banner-drawer').classList.toggle('is-open')
        })
      }
    }
  })();

  (function() {
    const about_btns = document.querySelectorAll('.pd-uvp-item-button') || [];
    const drawers = document.querySelectorAll('.about-story-drawer')

    about_btns.forEach(btn => {
      btn.addEventListener('click', () => {
        document.querySelector('html').classList.add('overflow-hidden');
        drawers[btn.dataset.position].classList.toggle('is-open');
      })
    })
  })();

  (function() {
    const whyus_btns = document.querySelectorAll('.pd-uvp-why-choose-us-btn') || [];
    const drawers = document.querySelectorAll('.why-us-drawer')

    whyus_btns.forEach((btn, idx) => {
      btn.addEventListener('click', () => {
        document.querySelector('html').classList.add('overflow-hidden');
        drawers[idx].classList.toggle('is-open');
      })
    })
  })();

  (function() {
    const faq_items = document.querySelectorAll('.pd-faq-dropdown-item') || [];

    faq_items.forEach(i => {
      
      console.log(i.dataset.position)
      i.addEventListener('click', () => {
        
        const section = document.querySelector(`#${i.dataset.position}`);
        console.log(section.scrollTop)

        window.scrollTo({
          top: section.offsetTop - 100,
          behavior: 'smooth'
        })
      });
    })
    
  })();

  (function() {
    const swiper = new Swiper(".announcement-swiper", {
      modules: [Autoplay, Navigation],
      loop: true,
      autoplay: {
        delay: 2500,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  })();

  // Handle read-more-drawer
  (function() {
    const readMoreBtns = document.querySelectorAll('.pd-uvp-read-more') || [];

    readMoreBtns.forEach((btn, idx) => {
      btn.addEventListener('click', () => {
        document.querySelector('html').classList.add('overflow-hidden');
        const drawer = document.getElementById(btn.dataset.drawerId);
        drawer.classList.toggle('is-open');
      })
    })
  })();
})();